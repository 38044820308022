import { useState, useRef } from 'react';
import { IonContent, 
         IonHeader, 
         IonPage, 
         IonTitle, 
         IonToolbar, 
         IonInput, 
         IonButton,
         IonImg,
         IonText,
         IonButtons,
         IonMenuButton,
         IonFooter } from '@ionic/react';
import CGLogo from '../img/chatGPT.png';
import AppLogo from '../img/app-logo.png';
import './Chatbot.css';

const Chatbot: React.FC = () => {

  const [prompt, setPrompt] = useState('');
  const ionInputEl = useRef<HTMLIonInputElement>(null);
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const onInput = (ev: any) => {
    const value = (ev.target as HTMLIonInputElement).value as string;
    setPrompt(value);

    const inputCmp = ionInputEl.current;
    if (inputCmp !== null) {
      inputCmp.value = value;
    }
    };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);
    
    // communicate with API
    // post input value 'prompt' to API end point 

    const response = await fetch('https://chatgptserver.tocanto.dev/chat', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ promptDescription: prompt }),
    });

    const data = await response.json();
    console.log(data);
    setResponse(data.promptQuery);
    setLoading(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>ChatGPT</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="wrapper">
        <IonImg src={AppLogo} alt="" className="app-logo"></IonImg>	
        <form onSubmit={handleSubmit}>
          <IonImg src={CGLogo} alt="" className={loading ? 'cg-logo loading' : 'cg-logo'} ></IonImg>
          <IonInput type="text" value={prompt} placeholder="Ask anything... :)" onIonInput={onInput} ref={ionInputEl}></IonInput>
          <IonButton type="submit" className="button-submit">Ask</IonButton>
        </form>
        <p className="response-area">
          <IonText>
          {loading ? 'loading...' : response}
          </IonText>
        </p>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonTitle>Developed by tocantodev @2023</IonTitle>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Chatbot;
